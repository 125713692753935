import { pattern as P, TrustSchemaPolicy } from "@ndn/trust-schema";

export const policy = new TrustSchemaPolicy();

policy.addPattern("#CERT", new P.ConcatPattern([
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_13"),
  new P.VariablePattern("_TAG_14"),
  new P.VariablePattern("_TAG_15"),
]));
policy.addPattern("#sitename", new P.VariablePattern("s10"));
policy.addPattern("_NODE_7", new P.ConcatPattern([
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
]));
policy.addPattern("_NODE_10", new P.ConcatPattern([
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
]));
policy.addPattern("#network", new P.OverlapPattern([
  new P.VariablePattern("network"),
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
]));
policy.addPattern("#rootcert", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_16"),
  new P.VariablePattern("_TAG_17"),
  new P.VariablePattern("_TAG_18"),
]));
policy.addPattern("#sitecert", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s10"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_19"),
  new P.VariablePattern("_TAG_20"),
  new P.VariablePattern("_TAG_21"),
]));
policy.addPattern("#routername", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s10"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("#routercert", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s10"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_22"),
  new P.VariablePattern("_TAG_23"),
  new P.VariablePattern("_TAG_24"),
]));
policy.addPattern("#operatorcert", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s10"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_25"),
  new P.VariablePattern("_TAG_26"),
  new P.VariablePattern("_TAG_27"),
]));
policy.addPattern("_NODE_60", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_34"),
  new P.VariablePattern("_TAG_35"),
  new P.VariablePattern("_TAG_36"),
]));
policy.addPattern("_NODE_84", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_43"),
  new P.VariablePattern("_TAG_44"),
  new P.VariablePattern("_TAG_45"),
]));
policy.addPattern("#lsdbdata", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s10"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.OverlapPattern([
    new P.VariablePattern("lsatype"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=names"),
      new P.ConstPattern("/8=adjacencies"),
      new P.ConstPattern("/8=coordinates"),
    ]),
  ]),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));
policy.addPattern("_NODE_49", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_31"),
  new P.VariablePattern("_TAG_32"),
  new P.VariablePattern("_TAG_33"),
]));
policy.addPattern("_NODE_73", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_40"),
  new P.VariablePattern("_TAG_41"),
  new P.VariablePattern("_TAG_42"),
]));
policy.addPattern("_NODE_43", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_28"),
  new P.VariablePattern("_TAG_29"),
  new P.VariablePattern("_TAG_30"),
]));
policy.addPattern("_NODE_67", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_37"),
  new P.VariablePattern("_TAG_38"),
  new P.VariablePattern("_TAG_39"),
]));
policy.addPattern("_NODE_45", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("_NODE_54", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s20"),
  new P.VariablePattern("s21"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.OverlapPattern([
    new P.VariablePattern("lsatype"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=names"),
      new P.ConstPattern("/8=adjacencies"),
      new P.ConstPattern("/8=coordinates"),
    ]),
  ]),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));
policy.addPattern("_NODE_69", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("_NODE_78", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s30"),
  new P.VariablePattern("s31"),
  new P.VariablePattern("s32"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.OverlapPattern([
    new P.VariablePattern("lsatype"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=names"),
      new P.ConstPattern("/8=adjacencies"),
      new P.ConstPattern("/8=coordinates"),
    ]),
  ]),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));

policy.addRule("#sitecert", "#rootcert");
policy.addRule("#routercert", "#operatorcert");
policy.addRule("#routercert", "_NODE_60");
policy.addRule("#routercert", "_NODE_84");
policy.addRule("#lsdbdata", "#routercert");
policy.addRule("#lsdbdata", "_NODE_49");
policy.addRule("#lsdbdata", "_NODE_73");
policy.addRule("#operatorcert", "#sitecert");
policy.addRule("#operatorcert", "_NODE_43");
policy.addRule("#operatorcert", "_NODE_67");
policy.addRule("_NODE_43", "#rootcert");
policy.addRule("_NODE_49", "#operatorcert");
policy.addRule("_NODE_49", "_NODE_60");
policy.addRule("_NODE_49", "_NODE_84");
policy.addRule("_NODE_54", "#routercert");
policy.addRule("_NODE_54", "_NODE_49");
policy.addRule("_NODE_54", "_NODE_73");
policy.addRule("_NODE_60", "#sitecert");
policy.addRule("_NODE_60", "_NODE_43");
policy.addRule("_NODE_60", "_NODE_67");
policy.addRule("_NODE_67", "#rootcert");
policy.addRule("_NODE_73", "#operatorcert");
policy.addRule("_NODE_73", "_NODE_60");
policy.addRule("_NODE_73", "_NODE_84");
policy.addRule("_NODE_78", "#routercert");
policy.addRule("_NODE_78", "_NODE_49");
policy.addRule("_NODE_78", "_NODE_73");
policy.addRule("_NODE_84", "#sitecert");
policy.addRule("_NODE_84", "_NODE_43");
policy.addRule("_NODE_84", "_NODE_67");
